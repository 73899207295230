import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/pageTemplate.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I've always liked to see articles where the first letter of the first paragraph is much larger and expands a few lines. So I wanted to try and do the same on my personal site.`}</p>
    <p>{`Since I am using Scss on my site, I will first show you how to do it using CSS. Then how you can do it with CSS, it won't be much of a difference, I'm sure you will be able to understand what's happening.`}</p>
    <div className="example">
  <div className="example-header">Example</div>
  <div className="article example-body">
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus ut velit sit amet tellus gravida interdum et eu nisl. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Maecenas ac pharetra magna. Nunc tincidunt dolor odio, ut mollis turpis lobortis et. Aenean in vulputate nulla. Vestibulum accumsan ultrices interdum. Integer tempus tellus ipsum, eget tempus leo efficitur non. Ut in dui in turpis pretium luctus. Integer posuere dolor sit amet felis volutpat posuere. Duis congue ut purus at faucibus.</p>
  </div>
    </div>
    <h2 {...{
      "id": "selectors",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#selectors",
        "aria-label": "selectors permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Selectors`}</h2>
    <p>{`Since the goal is to transform only the first letter of the first paragraph of your article, we need two selectors, the `}<inlineCode parentName="p">{`:first-of-type`}</inlineCode>{` and the `}<inlineCode parentName="p">{`:first-letter`}</inlineCode>{`.`}</p>
    <p>{`By adding the first-of-type selector to the paragraph, it will only apply the rules to the very first paragraph inside the article element. Since we are applying the rules to the first paragraph, we also need to add the first-letter selector to apply the rules only and only to the first letter in the first paragraph.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`  p:first-of-type {
    &:first-letter {
      float: left; 
      color: #FF721E; 
        font-size: 60px;
        line-height: 50px;
        padding-top: 4px;
        padding-right: 8px;
        padding-left: 3px;
    }
  }
`}</code></pre>
    <p>{`Using the `}<inlineCode parentName="p">{`float`}</inlineCode>{` rule, will make the first letter to move to the left and put the rest of the test on the same line as the big letter. We also need to increase the font-size and the line-height, otherwise, the letter will occupy the whole left space and will not allow the rest of the text to move under it.`}</p>
    <p>{`Finally, I added some padding just to add a bit more space around the letter. If you don't add some padding, the text will stay too close.`}</p>
    <h2 {...{
      "id": "responsiveness",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#responsiveness",
        "aria-label": "responsiveness permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Responsiveness`}</h2>
    <p>{`When users use your site on mobile, the first letter might look way too big. It sure did on my case, so I tweaked the `}<inlineCode parentName="p">{`font-size`}</inlineCode>{` and `}<inlineCode parentName="p">{`line-height`}</inlineCode>{` a bit, making the first letter smaller then added a media query for a desktop screen and then, increase the letter size back to the previous values.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`  p:first-of-type {
    &:first-letter {
      float: left; 
      color: #FF721E; 
      font-size: 45px;
      line-height: 43px;
      padding-top: 4px;
      padding-right: 8px;
      padding-left: 3px;
      @include desktop {
        font-size: 60px;
        line-height: 50px;
      }
    }
  }
`}</code></pre>
    <h2 {...{
      "id": "css-version",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#css-version",
        "aria-label": "css version permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`CSS Version`}</h2>
    <p>{`Well if you want to use this trick with CSS instead, I got you covered. The rules will look the same. You can just add the two selectors together. Note that you will need to add a media query here if you want to change the size of the letter in smaller screens.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`p:first-of-type:first-letter {
  float: left;
  color: #FF721E;
  font-size: 60px;
  line-height: 50px;
}
`}</code></pre>
    <p>{`I hope this helps you add that effect to your site, feel free to share it with me on Twitter `}<a parentName="p" {...{
        "href": "https://twitter.com/FabioRosado_"
      }}>{`@FabioRosado_`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      